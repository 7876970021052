import React, { useEffect, useState } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Account from '../../assets/img/illustrations/account.svg';
import api from '../../services/api';

const ConfirmAccount: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    async function confirmAccount() {
      const token = location.search.replace('?token=', '');

      if (!token) {
        history.push('/');
      }

      await api.post('/user/confirm', { token });

      setIsConfirmed(true);
    }

    confirmAccount();
  }, [history, location]);

  return (
    <>
      {isConfirmed ? (
        <section className="section-border border-primary">
          <div className="container d-flex flex-column">
            <div className="row align-items-center justify-content-center gx-0 min-vh-100">
              <div className="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11">
                <img src={Account} alt="..." className="img-fluid" />
              </div>
              <div className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11">
                <h1 className="display-3 fw-bold text-center">
                  Conta confirmada.
                </h1>

                <p className="mb-5 text-center text-muted">
                  Nós já confirmamos sua conta, você já pode fazer login
                </p>

                <div className="text-center">
                  <span className="btn btn-primary">
                    <Link to="/" style={{ color: '#fff' }}>
                      Voltar
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 90,
          }}
        >
          <ReactLoading type="spin" color="#D71634" height={667} width={175} />
        </div>
      )}
    </>
  );
};

export default ConfirmAccount;
