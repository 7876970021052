import React from 'react';

const Privacy: React.FC = () => {
  return (
    <>
      <section className="pt-8 pt-md-11 pb-8 pb-md-14">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md">
              <h1 className="display-4 mb-2">Política de privacidade</h1>

              <p className="fs-lg text-gray-700 mb-md-0">
                Atualizado em 11/03/2021
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr className="my-6 my-md-8" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-8">
              <h3 className="mb-5">Política de privacidade</h3>

              <p className="text-gray-800">
                A Automação Curitiba construiu o aplicativo Recrutalentos como
                um aplicativo gratuito. Este SERVIÇO é prestado pela Automação
                Curitiba sem nenhum custo e deve ser utilizado no estado em que
                se encontra. Esta página é usada para informar os visitantes
                sobre nossas políticas de coleta, uso e divulgação de
                Informações Pessoais, caso alguém decida usar nosso Serviço. Se
                você optar por usar nosso Serviço, você concorda com a coleta e
                uso de informações em relação a esta política. As informações
                pessoais que coletamos são usadas para fornecer e melhorar o
                serviço. Não usaremos ou compartilharemos suas informações com
                ninguém, exceto conforme descrito nesta Política de Privacidade.
                Os termos utilizados nesta Política de Privacidade têm os mesmos
                significados que nos nossos Termos e Condições, os quais se
                encontram disponíveis na Recrutalentos salvo definição em
                contrário nesta Política de Privacidade.
              </p>

              <h3 className="mb-5">Coleta e uso de informações</h3>

              <p>
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to E-mail, name. The
                information that we request will be retained by us and used as
                described in this privacy policy. The app does use third party
                services that may collect information used to identify you. Link
                to privacy policy of third party service providers used by the
                app
              </p>

              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check" />
                </span>

                <p className="text-gray-800">
                  <a href="https://policies.google.com/privacy">
                    Google Play Services
                  </a>
                </p>
              </div>
              <h3 className="mb-5">Dados de registro</h3>

              <p className="text-gray-800">
                Queremos informar que sempre que você utiliza nosso Serviço, em
                caso de erro no aplicativo, coletamos dados e informações (por
                meio de produtos de terceiros) em seu telefone denominado Log
                Data. Estes dados de registro podem incluir informações como
                endereço de protocolo de Internet ("IP") do dispositivo, nome do
                dispositivo, versão do sistema operacional, configuração do
                aplicativo ao utilizar nosso serviço, hora e data de uso do
                serviço e outras estatísticas .
              </p>

              <h3 className="mb-5">Cookies</h3>

              <p className="text-gray-800">
                Cookies são arquivos com uma pequena quantidade de dados que são
                comumente usados como identificadores exclusivos anônimos. Eles
                são enviados para o seu navegador a partir dos sites que você
                visita e são armazenados na memória interna do seu dispositivo.
                Este Serviço não usa esses “cookies” explicitamente. No entanto,
                o aplicativo pode usar código de terceiros e bibliotecas que
                usam "cookies" para coletar informações e melhorar seus
                serviços. Você tem a opção de aceitar ou recusar esses cookies e
                saber quando um cookie está sendo enviado para o seu
                dispositivo. Se você optar por recusar nossos cookies, pode não
                ser capaz de usar algumas partes deste Serviço.
              </p>

              <h3 className="mb-5">Provedores de serviço</h3>

              <p>
                Podemos empregar empresas terceirizadas e indivíduos devido aos
                seguintes motivos:
              </p>

              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check" />
                </span>

                <p className="text-gray-800">Para facilitar nosso serviço;</p>
              </div>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check" />
                </span>

                <p className="text-gray-800">
                  Para fornecer o serviço em nosso nome;
                </p>
              </div>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check" />
                </span>

                <p className="text-gray-800">
                  Para realizar serviços relacionados com o serviço;
                </p>
              </div>
              <div className="d-flex">
                <span className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                  <i className="fe fe-check" />
                </span>

                <p className="text-gray-800">
                  Para nos ajudar a analisar como nosso serviço é usado.
                </p>
              </div>

              <p className="text-gray-800">
                Queremos informar aos usuários deste Serviço que esses terceiros
                têm acesso às suas Informações Pessoais. O motivo é realizar as
                tarefas atribuídas a eles em nosso nome. No entanto, eles são
                obrigados a não divulgar ou usar as informações para qualquer
                outra finalidade.
              </p>

              <h3 className="mb-5">Segurança</h3>

              <p className="text-gray-800">
                Valorizamos sua confiança em nos fornecer suas informações
                pessoais, portanto, estamos nos empenhando para usar meios
                comercialmente aceitáveis de protegê-las. Mas lembre-se que
                nenhum método de transmissão pela internet, ou método de
                armazenamento eletrônico é 100% seguro e confiável, e não
                podemos garantir sua segurança absoluta.
              </p>

              <h3 className="mb-5">Links para outros sites</h3>

              <p className="text-gray-800">
                Este serviço pode conter links para outros sites. Se você clicar
                em um link de terceiros, será direcionado a esse site. Observe
                que esses sites externos não são operados por nós. Portanto,
                recomendamos enfaticamente que você analise a Política de
                Privacidade desses sites. Não temos controle e não assumimos
                responsabilidade pelo conteúdo, políticas de privacidade ou
                práticas de quaisquer sites ou serviços de terceiros. Community
                Verified icon
              </p>

              <h3 className="mb-5">Privacidade das crianças</h3>

              <p className="text-gray-800">
                Esses Serviços não se dirigem a ninguém com menos de 13 anos.
                Não coletamos intencionalmente informações de identificação
                pessoal de crianças com menos de 13 anos. No caso de
                descobrirmos que uma criança menor de 13 anos nos forneceu
                informações pessoais, nós as apagamos imediatamente de nossos
                servidores. Se você é um pai ou responsável e sabe que seu filho
                nos forneceu informações pessoais, entre em contato para que
                possamos tomar as medidas necessárias.
              </p>

              <h3 className="mb-5">Mudanças nesta Política de Privacidade</h3>

              <p className="text-gray-800">
                Podemos atualizar nossa Política de Privacidade de tempos em
                tempos. Portanto, é recomendável revisar esta página
                periodicamente para verificar quaisquer alterações. Iremos
                notificá-lo de quaisquer alterações, publicando a nova Política
                de Privacidade nesta página. Esta política entra em vigor a
                partir de 11/03/2021 Community Verified icon
              </p>

              <h3 className="mb-5">Fale conosco</h3>

              <p className="text-gray-800">
                Se você tiver alguma dúvida ou sugestão sobre nossa Política de
                Privacidade, não hesite em nos contatar pelo e-mail
                suporte@automacaocuritiba.com. Esta página de política de
                privacidade foi criada em privacypolicytemplate.net e modificada
                / gerada pelo App Privacy Generator
              </p>
            </div>
            <div className="col-12 col-md-4">
              <div className="card shadow-light-lg">
                <div className="card-body">
                  <h4>Entre em contato</h4>

                  <p className="fs-sm text-gray-800 mb-5">
                    Tem, alguma dúvida? Fale conosco
                  </p>

                  <h6 className="fw-bold text-uppercase text-gray-700 mb-2">
                    Mande um e-mail
                  </h6>

                  <p className="fs-sm mb-0">
                    <a
                      href="mailto:suporte@automacaocuritiba.com"
                      className="text-reset"
                    >
                      suporte@automacaocuritiba.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
