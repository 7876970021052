import React, { useCallback, useEffect, useRef } from 'react';
import { FaWhatsapp, FaPhoneAlt  } from 'react-icons/fa';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../services/api';
import Logo from '../../assets/img/illustrations/logo.svg';

import getValidationErrors from '../../utils/getValidationErrors';
import {GTag} from '@deptno/gtag-react'

import Thanks from '../../assets/img/illustrations/thanks.svg';

// Social Media
import Facebook from '../../assets/img/illustrations/facebook.svg';
import Instagram from '../../assets/img/illustrations/instagram.svg';
import Youtube from '../../assets/img/illustrations/youtube.svg';
import Globe from '../../assets/img/illustrations/globe.svg';


interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const ThankYou: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    <GTag id={"AW-462629086/qho0CKH9jKgDEN7RzNwB"} />
  }, []);

  return (
    <section className="section-border border-primary">
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center gx-0 min-vh-100">
          <div className="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11">
            <img src={Thanks} alt="..." className="img-fluid" />
          </div>
          <div className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11">
          <h1 className="mb-0 fw-bold text-center">Muito Obrigado!</h1>

          <h3 className="mb-9 mt-5 text-center text-muted">
            Recebemos seus dados, entraremos em contato!
          </h3>
          <h3 className="mb-9 mt-5 text-center text-muted">
            Caso deseje, poderá estar entrando em contato com um consultor em um de nossos telefones: 
          </h3>
          <h4 className='text-danger text-center'><FaPhoneAlt className='IoLogoWhatsapp' /> (41) 3242-3567</h4>
          <h4 className='text-danger text-center'><FaPhoneAlt className='IoLogoWhatsapp' /> (41) 3243-9816</h4>
          <a href='https://api.whatsapp.com/send/?phone=5541996037865&text&app_absent=0'><h3 className=' text-center' style={{color: 'green', }} ><FaWhatsapp className='IoLogoWhatsapp' /> (41) 99603-7865</h3></a>
          <a href="https://www.automacaocuritiba.com.br" className="btn w-100 btn-primary">
            Conheça nosso Site!
          </a>
          </div>
          
        </div>
        
      </div>
      <div className="col-md-6 offset-md-3">
        <div className='col-md-6 offset-md-3 ' >
            <img src={Logo} alt="..." className="footer-brand img-fluid mb-2" />
      
          <p className="text-gray-700 mb-2 text-center">
          Automação Curitiba
          </p>
          <div className='d-flex justify-content-center'>
            <ul className="list-unstyled list-inline list-social mb-6 mb-md-0 " >
              <li className="list-inline-item list-social-item me-3">
                <a href="https://www.instagram.com/automacao_curitiba/" target='_blank' className="text-decoration-none">
                  <img src={Instagram} className="list-social-icon" alt="..." />
                </a>
              </li>
              <li className="list-inline-item list-social-item me-3" style={{marginLeft: 15}}>
                <a href="https://www.facebook.com/automacaocuritiba" target='_blank' className="text-decoration-none">
                  <img src={Facebook} className="list-social-icon" alt="..." />
                </a>
              </li>
              <li className="list-inline-item list-social-item me-3" style={{marginLeft: 15}}>
                <a href="https://www.youtube.com/c/AutomacaocuritibaBra" target='_blank' className="text-decoration-none">
                  <img src={Youtube} className="list-social-icon" alt="..." />
                </a>
              </li>
              <li className="list-inline-item list-social-item">
                <a href="https://www.automacaocuritiba.com.br/" target='_blank' className="text-decoration-none">
                  <img src={Globe} className="list-social-icon" alt="..." style={{marginLeft: 15}} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      
    </section>
 
    
  );
};

export default ThankYou;
